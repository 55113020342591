import React from "react"
import NavBar from "./navBar"

const Header = () => {
  return (
    <>
      <div className="header">
        <NavBar />
      </div>
      <svg
        className="headerSvg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon points="0,100 0,80 100,0 100,100" />
      </svg>
    </>
  )
}

export default Header
