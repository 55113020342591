import React, { useEffect } from "react"
import Header from "../components/header"
import Projects from "../components/projects"
import About from "../components/about"
import Contact from "../components/contact"
import Footer from "../components/footer"

import "../styles/style.scss"

const IndexPage = () => {
  useEffect(() => {
    document.title = "Darcy Margetts"
  }, [])
  return (
    <div className="App">
      <Header />
      <Projects />
      <About />
      <Contact />
      <Footer />
    </div>
  )
}

export default IndexPage
