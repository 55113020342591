import React from "react"

const About = () => {
  return (
    <div className="about" id="about">
      <div className="aboutContainer">
        <h1>About</h1>
        <p>
          Nostrud eiusmod est nostrud Lorem Lorem commodo occaecat aliquip esse
          voluptate anim tempor. Minim consectetur sint deserunt elit ad anim
          officia commodo velit minim. Consequat aliquip tempor sint fugiat nisi
          ullamco labore nisi. Labore elit velit quis consequat est dolor
          laboris ullamco voluptate occaecat. Quis eiusmod in do irure pariatur
          nisi adipisicing. Exercitation cillum excepteur sunt eu velit et culpa
          nulla qui eu non fugiat officia officia. Occaecat veniam id cupidatat
          reprehenderit laboris quis culpa.Ea quis sunt eiusmod excepteur do
          dolore exercitation ea anim cillum.
        </p>
        <img
          alt="me"
          src="https://generative-placeholders.glitch.me/image?width=600&height=300&style=123&color=28"
        />
        <div className="skills">
          <ul>
            <h2>Front End</h2>
            <li>HTML</li>
            <li>CSS</li>
            <li>Javascript</li>
            <li>React</li>
            <li>Sass</li>
          </ul>

          <ul>
            <h2>Back End</h2>
            <li>PHP</li>
            <li>Nodejs</li>
            <li>Expressjs</li>
            <li>PostgreSQL</li>
            <li>MySQL</li>
          </ul>

          <ul>
            <h2>Other tools</h2>
            <li>Git</li>
            <li>Jest</li>
            <li>npm</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default About
