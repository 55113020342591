import React from "react"
import Logo from "../images/Logo.png"

const NavBar = props => {
  return (
    <>
      <nav id="navbar">
        <img className="logo" src={Logo} alt="logo" />
        <h1>Darcy Margetts</h1>
        <ul>
          <li>
            <a href="#projects">Projects</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default NavBar
