import React from "react"

function Projects() {
  return (
    <div className="projects" id="projects">
      <div className="project">
        <h2>Project 1</h2>
        <p>
          Veniam ullamco elit dolore velit deserunt consequat nostrud ipsum esse
          reprehenderit nulla. Qui deserunt anim incididunt ut magna ex
          excepteur nisi enim in magna mollit. Occaecat consequat nisi magna
          ipsum est culpa sunt minim laboris ex cillum. Veniam proident sit
          nulla do adipisicing. Sunt velit ex sunt proident nulla mollit nostrud
          culpa qui mollit. Cillum nisi irure deserunt tempor irure consectetur
          elit. Quis velit aute enim dolor ad dolore elit nulla laboris esse
          cillum.
        </p>
        <img
          alt="project "
          src="https://generative-placeholders.glitch.me/image?width=500&height=300&style=cubic-disarray&color=62"
        />
        <div className="gridButtons">
          <div className="buttonContainer">
            <div className="svgContainer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18px"
                width="30px"
                viewBox="0 0 512 512"
              >
                <path
                  d="M492.703,0H353.126c-10.658,0-19.296,8.638-19.296,19.297c0,10.658,8.638,19.296,19.296,19.296h120.281v120.281
			c0,10.658,8.638,19.296,19.296,19.296c10.658,0,19.297-8.638,19.297-19.296V19.297C512,8.638,503.362,0,492.703,0z"
                />

                <path
                  d="M506.346,5.654c-7.538-7.539-19.747-7.539-27.285,0L203.764,280.95c-7.539,7.532-7.539,19.753,0,27.285
			c3.763,3.769,8.703,5.654,13.643,5.654c4.933,0,9.873-1.885,13.643-5.654L506.346,32.939
			C513.885,25.407,513.885,13.186,506.346,5.654z"
                />

                <path
                  d="M427.096,239.92c-10.658,0-19.297,8.638-19.297,19.296v214.191H38.593V104.201h214.191
			c10.658,0,19.296-8.638,19.296-19.296s-8.638-19.297-19.296-19.297H19.297C8.638,65.608,0,74.246,0,84.905v407.799
			C0,503.362,8.638,512,19.297,512h407.799c10.664,0,19.296-8.638,19.296-19.297V259.216
			C446.392,248.558,437.754,239.92,427.096,239.92z"
                />
              </svg>
            </div>
          </div>
          <div className="buttonContainer">
            <div className="svgContainer">
              <svg
                height="30"
                viewBox="0 0 24 24"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m12 .5c-6.63 0-12 5.28-12 11.792 0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56 4.801-1.548 8.236-5.97 8.236-11.173 0-6.512-5.373-11.792-12-11.792z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="project">
        <h2>Project 1</h2>
        <p>
          Veniam ullamco elit dolore velit deserunt consequat nostrud ipsum esse
          reprehenderit nulla. Qui deserunt anim incididunt ut magna ex
          excepteur nisi enim in magna mollit. Occaecat consequat nisi magna
          ipsum est culpa sunt minim laboris ex cillum. Veniam proident sit
          nulla do adipisicing. Sunt velit ex sunt proident nulla mollit nostrud
          culpa qui mollit. Cillum nisi irure deserunt tempor irure consectetur
          elit. Quis velit aute enim dolor ad dolore elit nulla laboris esse
          cillum.
        </p>
        <img
          alt="project"
          src="https://generative-placeholders.glitch.me/image?width=500&height=300&style=triangles&gap=100&colors=0"
        />
        <div className="gridButtons">
          <div className="buttonContainer">
            <div className="svgContainer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18px"
                width="30px"
                viewBox="0 0 512 512"
              >
                <path
                  d="M492.703,0H353.126c-10.658,0-19.296,8.638-19.296,19.297c0,10.658,8.638,19.296,19.296,19.296h120.281v120.281
			c0,10.658,8.638,19.296,19.296,19.296c10.658,0,19.297-8.638,19.297-19.296V19.297C512,8.638,503.362,0,492.703,0z"
                />

                <path
                  d="M506.346,5.654c-7.538-7.539-19.747-7.539-27.285,0L203.764,280.95c-7.539,7.532-7.539,19.753,0,27.285
			c3.763,3.769,8.703,5.654,13.643,5.654c4.933,0,9.873-1.885,13.643-5.654L506.346,32.939
			C513.885,25.407,513.885,13.186,506.346,5.654z"
                />

                <path
                  d="M427.096,239.92c-10.658,0-19.297,8.638-19.297,19.296v214.191H38.593V104.201h214.191
			c10.658,0,19.296-8.638,19.296-19.296s-8.638-19.297-19.296-19.297H19.297C8.638,65.608,0,74.246,0,84.905v407.799
			C0,503.362,8.638,512,19.297,512h407.799c10.664,0,19.296-8.638,19.296-19.297V259.216
			C446.392,248.558,437.754,239.92,427.096,239.92z"
                />
              </svg>
            </div>
          </div>
          <div className="buttonContainer">
            <div className="svgContainer">
              <svg
                height="30"
                viewBox="0 0 24 24"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m12 .5c-6.63 0-12 5.28-12 11.792 0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56 4.801-1.548 8.236-5.97 8.236-11.173 0-6.512-5.373-11.792-12-11.792z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="project">
        <h2>Project 1</h2>
        <p>
          Veniam ullamco elit dolore velit deserunt consequat nostrud ipsum esse
          reprehenderit nulla. Qui deserunt anim incididunt ut magna ex
          excepteur nisi enim in magna mollit. Occaecat consequat nisi magna
          ipsum est culpa sunt minim laboris ex cillum. Veniam proident sit
          nulla do adipisicing. Sunt velit ex sunt proident nulla mollit nostrud
          culpa qui mollit. Cillum nisi irure deserunt tempor irure consectetur
          elit. Quis velit aute enim dolor ad dolore elit nulla laboris esse
          cillum.
        </p>
        <img
          alt="project"
          src="https://generative-placeholders.glitch.me/image?width=500&height=300&style=tiles&colors=14"
        />
        <div className="gridButtons">
          <div className="buttonContainer">
            <div className="svgContainer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18px"
                width="30px"
                viewBox="0 0 512 512"
              >
                <path
                  d="M492.703,0H353.126c-10.658,0-19.296,8.638-19.296,19.297c0,10.658,8.638,19.296,19.296,19.296h120.281v120.281
			c0,10.658,8.638,19.296,19.296,19.296c10.658,0,19.297-8.638,19.297-19.296V19.297C512,8.638,503.362,0,492.703,0z"
                />

                <path
                  d="M506.346,5.654c-7.538-7.539-19.747-7.539-27.285,0L203.764,280.95c-7.539,7.532-7.539,19.753,0,27.285
			c3.763,3.769,8.703,5.654,13.643,5.654c4.933,0,9.873-1.885,13.643-5.654L506.346,32.939
			C513.885,25.407,513.885,13.186,506.346,5.654z"
                />

                <path
                  d="M427.096,239.92c-10.658,0-19.297,8.638-19.297,19.296v214.191H38.593V104.201h214.191
			c10.658,0,19.296-8.638,19.296-19.296s-8.638-19.297-19.296-19.297H19.297C8.638,65.608,0,74.246,0,84.905v407.799
			C0,503.362,8.638,512,19.297,512h407.799c10.664,0,19.296-8.638,19.296-19.297V259.216
			C446.392,248.558,437.754,239.92,427.096,239.92z"
                />
              </svg>
            </div>
          </div>
          <div className="buttonContainer">
            <div className="svgContainer">
              <svg
                height="30"
                viewBox="0 0 24 24"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m12 .5c-6.63 0-12 5.28-12 11.792 0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56 4.801-1.548 8.236-5.97 8.236-11.173 0-6.512-5.373-11.792-12-11.792z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Projects
