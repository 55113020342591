import React from "react"

const Contact = () => {
  return (
    <div className="contact" id="contact">
      <form>
        <h1>Contact</h1>
        <input type="text" placeholder="Name" />
        <input type="email" placeholder="Email" />
        <textarea placeholder="Message"></textarea>
        <button>Send</button>
      </form>
    </div>
  )
}

export default Contact
